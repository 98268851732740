.focusVisibleOutline {
  &:focus-visible {
    outline-offset: -3px;
  }
}

.accountDivider {
  --height: 0;

  position: relative;
  height: var(--height);
  margin: var(--v-space-8) 0;
  background: var(--c-menu-color-active);
}

.alignWithScrollbars {
  scrollbar-width: thin; /* Firefox */
  overflow-y: auto;
  scrollbar-gutter: stable;
}

.thinScrollbar {
  --scrollbar-bg-color: var(--v-color-background-secondary);
  --scrollbar-handle-color: var(--v-color-ornament-primary);
  overflow-y: auto;
  scrollbar-gutter: stable;

  scrollbar-color: var(--scrollbar-handle-color) var(--scrollbar-bg-color);

  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-handle-color);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-bg-color);
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}

.topFade {
  position: relative;
  &::after {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: var(--v-space-8);
    content: '';
    background-image: var(--c-color-fade-background);
    border-inline-end: var(--v-space-8) solid var(--v-color-background-primary);
  }
}

.menuBase {
  --top-offset: var(--c-space-80);

  display: none;
  @media (--v-from-lg) {
    position: sticky;
    top: var(--top-offset);
    display: block;
    max-width: calc(var(--c-menu-width) + var(--v-space-8));
    height: fit-content;
    max-height: calc(100vh - var(--c-menu-height-offset));
    padding-top: var(--v-space-48);
    overflow: hidden;
  }
}

.menuItemText {
  margin-inline-start: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menuItemBase {
  composes: focusVisibleOutline;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--v-space-48);
  padding-inline-end: var(--v-space-24);
  background: var(--v-color-background-primary);
  border-inline-start: var(--v-space-4) solid var(--v-color-background-primary);
  &:hover {
    background: var(--v-color-background-secondary);
    border-color: var(--v-color-background-secondary);
    transition: var(--v-transition-default);
  }
}

.categoryLabel {
  padding: var(--v-space-16) 0 var(--v-space-8) var(--c-space-12);
  white-space: nowrap;
  pointer-events: none;
}

.menuItemCloseModule {
  flex-direction: row;
  gap: var(--v-space-8);
  align-items: center;
  justify-content: flex-start;
  padding-inline-start: var(--v-space-8);
  margin-top: var(--v-space-8);
  color: var(--v-color-foreground-secondary);
  text-transform: uppercase;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-inline-end: var(--v-space-24);
}

.filledLabel {
  width: 100%;
  background: var(--c-menu-color-active);
  border-inline-start-color: var(--c-menu-color-active);
  &:hover {
    background: var(--c-menu-color-active-hover);
    border-inline-start-color: var(--c-menu-color-active-hover);
    transition: var(--v-transition-default);
  }
}

.chevron {
  display: flex;
  justify-content: center;
  padding: var(--v-space-16);
  margin-inline-start: auto;
  & > img {
    transition: rotate 300ms var(--c-transition-timing-function);
    transform-origin: center;
  }
}

.chevronHighlight {
  &:hover {
    background-color: var(--c-menu-color-active);
    body[data-color-mode='dark'] & {
      background-color: var(--c-menu-color-active);
    }
  }
}

.lock {
  display: flex;
  margin-inline-end: var(--v-space-16);
}

.tocSlider {
  --active-index: 0;

  position: absolute;
  z-index: 1;
  width: var(--v-space-4);
  height: 100%;
  background-color: var(--v-color-background-secondary);
  &::after {
    position: absolute;
    width: var(--v-space-4);
    height: var(--v-space-48);
    content: '';
    background: var(--c-menu-color-active);
    transition: transform 200ms linear;
    transform: translateY(calc(var(--active-index) * 100%));
  }
}

.subMenu {
  position: relative;
  margin-inline-start: var(--c-space-40);
}

.subMenuWrapper {
  display: grid;
  overflow: hidden;
}

.subMenuInner {
  min-height: 0;
}

.subMenuEnter {
  grid-template-rows: 0fr;
}

.subMenuEnterActive {
  grid-template-rows: 1fr;
  transition: grid-template-rows 0.5s ease;
}

.subMenuExit {
  grid-template-rows: 1fr;
}

.subMenuExitActive {
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease;
}
