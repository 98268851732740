.topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: var(--c-header-height-until-l);
  padding: 0px var(--v-space-24);
  background: var(--v-color-background-primary);
  border-bottom: 1px solid var(--v-color-ornament-primary);
}

.breadcrumb {
  display: flex;
  flex-direction: row;
  gap: var(--v-space-4);
  align-items: center;
  max-width: 70%;
}

.breadcrumbText {
  &:first-child,
  &:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
