.backdrop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--v-color-always-black);
  opacity: 0.8;
  @media (--v-from-lg) {
    display: none;
  }
}

.backdropEnter {
  opacity: 0;
}

.backdropEnterActive {
  opacity: 0.8;
  transition: opacity 500ms;
}

.backdropExitActive {
  opacity: 0;
  transition: opacity 500ms;
}
