.accountLink {
  font-weight: var(--v-font-emphasis-weight);
  color: var(--v-color-foreground-accent-blue);
  &:hover {
    text-decoration: underline;
  }
}

.accountItem {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--c-menu-account-item-height-mobile);
  padding: 0 var(--v-space-24);
  outline-offset: -1px;
  transition: 0.2s ease;
  &.active {
    background: var(--v-color-background-secondary);
    border-color: var(--v-color-ornament-primary);
  }
  @media (--v-from-lg) {
    height: var(--v-space-64);
    padding: 0 var(--c-space-40) 0 var(--c-space-12);
    background-color: var(--v-color-background-primary);
    border-inline-start: var(--v-space-4) solid
      var(--v-color-background-primary);
    &.active {
      background: var(--c-menu-color-active);
      border-color: var(--c-menu-color-active);
    }
  }
}

.accountItemHover {
  @media (--v-from-lg) {
    &:hover {
      background: var(--v-color-background-secondary);
      border-color: var(--v-color-background-secondary);
    }
    &.active {
      &:hover {
        background: var(--c-menu-color-active-hover);
        border-color: var(--c-menu-color-active-hover);
      }
    }
  }
}

.accountItemInner {
  display: flex;
  flex-grow: 1;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  max-width: 100%;
  border-bottom: 1px solid var(--v-color-ornament-primary);
  @media (--v-from-lg) {
    border-bottom: 0;
  }
}

.userName {
  margin-inline-end: var(--v-space-24);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.signUp {
  padding-top: var(--v-space-4);
  &:hover {
    text-decoration: underline;
  }
  @media (--v-from-lg) {
    padding-top: 0;
  }
}

.logOutText {
  font-size: var(--v-font-14-size);
  color: var(--v-color-foreground-secondary);
  &:hover {
    color: var(--v-color-foreground-primary);
    text-decoration: underline;
  }
}

.logOutTextSpace {
  /* Manually add row for LogOut since it's absolute positioned */
  font-size: var(--v-font-14-size);
}

.logOutPlacement {
  position: absolute;
  top: var(--v-space-48);
  inset-inline-start: var(--v-space-24);
  @media (--v-from-lg) {
    top: var(--v-space-32);
    inset-inline-start: var(--v-space-16);
  }
}

.loadingTextContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--v-space-8);
  justify-content: center;
}

.loadingAvatar {
  width: var(--v-space-32);
  overflow: hidden;
  border-radius: 50%;
}
