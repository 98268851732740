.drawerContainer {
  --level: 0;

  position: relative;
  top: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  transition: transform 0.5s var(--c-transition-timing-function);
  transform: translateX(calc(var(--level) * var(--c-menu-width) * -1));
}

.drawerBlock {
  --max-height: 0;
  --padding-bottom: var(--v-space-48);
  --scrollbar-bg-color: var(--v-color-background-primary);

  width: var(--c-menu-width);
  max-height: calc(100vh - var(--max-height));
  padding-top: var(--v-space-8);
  padding-bottom: var(--padding-bottom);
}
