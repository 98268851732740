.tocContainer {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  background: var(--v-color-background-primary);
  transition: top 200ms ease-out;
  @media (--v-from-lg) {
    display: none;
  }
}

.tocBar {
  display: flex;
  justify-content: space-between;
  padding-inline: var(--v-space-24) var(--v-space-16);
  background: var(--v-color-background-primary);
  border-bottom: 1px solid var(--v-color-ornament-primary);
}

.tocBreadCrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: calc(100% - 50px);
}

.tocBreadCrumbTextWrap {
  display: flex;
  align-items: stretch;
  min-height: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.tocBreadCrumbItem {
  padding: 0.5em 0;
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tocWrapper {
  position: relative;
  transition: height 500ms ease-out;
}

.tocSubMenu {
  height: 100%;
  overflow-y: auto;
}

.tocSubMenuGradient {
  position: absolute;
  top: 0;
  z-index: 1;
  width: calc(100% - var(--v-space-8));
  height: 43px;
  pointer-events: none;
  background: linear-gradient(
    var(--v-color-background-primary),
    rgba(255 255 255 / 0%)
  );
  opacity: 0;
  transition-property: opacity;
  inset-inline-start: 0;
}

.tocSubMenuInner {
  position: relative;
  padding: var(--v-space-16) var(--v-space-24) calc(var(--v-space-8) * 10);
}

.tocSubMenuHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--v-space-48);
  text-overflow: ellipsis;
  white-space: nowrap;
}
