.avatar {
  --bg-color: var(--v-color-background-secondary);

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--v-space-32);
  height: var(--v-space-32);
  overflow: hidden;
  text-transform: uppercase;
  background-color: var(--bg-color);
  border-radius: 100%;
  @media (--v-until-lg) {
    background-color: var(--v-color-ornament-primary);
  }
}
