.drawerContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: transform 300ms ease;
  inset-inline-start: 0;
  @media (--v-from-md) {
    width: var(--c-mobile-menu-width);
  }
}

.drawerBlock {
  --scrollbar-bg-color: var(--v-color-background-secondary);
  --scrollbar-handle-color: var(--v-color-ornament-primary);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: stretch;
  width: 100%;
  padding-bottom: var(--v-size-24);
  overflow: auto;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-handle-color) var(--scrollbar-bg-color);
}
