.content {
  --offset-small: 10px;
  --offset-medium: 10px;
  --offset-large: 10px;

  min-height: calc(100vh - var(--offset-small));
  outline: none;
  @media (--v-from-md) {
    min-height: calc(100vh - var(--offset-medium));
  }
  @media (--v-from-lg) {
    min-height: calc(100vh - var(--offset-large));
  }
}

.pageLayout {
  width: 100%;
  margin: 0 auto;
  @media (--v-from-lg) {
    display: grid;
    flex-grow: 1;
    grid-template-columns: var(--c-page-layout-grid);
    column-gap: 24px;
    max-width: var(--c-max-content-width-12-columns);
  }
}

.innerContent {
  @media (--oip-to-grid-max-width) {
    padding: 0 var(--v-space-24);
  }
  @media (--v-until-md) {
    padding: 0;
  }
}

.page {
  -web-kit-tap-highlight-color: var(--c-color-tap-highlight);
}
