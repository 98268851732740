.wrapper {
  position: fixed;
  bottom: var(--v-space-8);
  z-index: 500;
  inset-inline-end: var(--v-space-8);

  @media (--v-from-md) {
    bottom: var(--v-space-16);
    inset-inline-end: var(--v-space-16);
  }
}

.exitToast {
  max-height: 300px;
  opacity: 1;
  transform: translateX(0);
}

.enterToast {
  max-height: 0;
  opacity: 0;
  transform: translateX(200%);
}

.enterActiveToast,
.exitActiveToast {
  transition: 300ms ease;
}

.enterActiveToast {
  max-height: 300px;
  opacity: 1;
  transform: translateX(0);
}

.exitActiveToast {
  max-height: 0;
  opacity: 0;
  transform: translateX(200%);
}
