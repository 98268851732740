.wrapper {
  --top: 0;
  position: fixed;
  top: var(--top);
  z-index: 200;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--v-color-background-secondary);
}

.innerWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: var(--c-max-content-width-12-columns);
}

.content {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  width: 100%;
  height: var(--c-header-height-until-l);
  padding: 0 var(--c-space-12) 0 var(--v-space-24);
  @media (--v-from-lg) {
    grid-template-columns: var(--c-page-layout-grid);
    grid-gap: var(--v-space-24);
    justify-content: unset;
    height: var(--c-header-height-froml);
    padding: 0;
  }
  @media (--v-from-lg) and (--oip-to-grid-max-width) {
    padding-inline-start: var(--v-space-24);
  }
}

.logoWrapper {
  align-self: center;
  width: max-content;
}

.navItem {
  display: flex;
  align-items: center;
  height: 100%;
  padding: var(--v-space-24) 0;
  letter-spacing: 0.3px;
  border-bottom: solid var(--v-color-background-secondary) 3px;
  &:hover {
    border-bottom: solid var(--v-color-foreground-accent-blue) 3px;
  }
}
.navItemActive {
  border-color: var(--v-color-foreground-accent-blue);
}

.hideOnMobile {
  display: none;
  @media (--v-from-lg) {
    display: flex;
  }
}
