.pulseAnimation {
  --height: 10px;
  --max-width: none;

  width: 100%;
  max-width: var(--max-width);
  height: var(--height);

  animation-name: pulse;
  animation-duration: 3000ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

@keyframes pulse {
  0% {
    background-color: var(--v-color-background-secondary);
  }
  50% {
    background-color: var(--v-color-ornament-primary);
  }
  100% {
    background-color: var(--v-color-background-secondary);
  }
}
