.wrapper {
  --top: var(--c-header-height-froml);
  position: fixed;
  top: var(--top);
  inset-inline-start: 50%;
  z-index: 1;
  width: 100%;
  height: 70px;
  pointer-events: none;
  transform: translateX(-50%);
  @media (--v-until-lg) {
    display: none;
  }
  @media (--oip-to-grid-max-width) {
    padding: 0 var(--v-space-24);
  }
}

.innerWrapper {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: var(--v-color-background-primary);
  opacity: 0;
  transition: opacity 300ms var(--c-transition-timing-function);
  &::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    pointer-events: none;
    content: '';
    background-image: var(--c-color-fade-background);
    transform: translateY(100%);
  }
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: var(--c-content-max-width);
  margin: 0 auto;
  margin-top: 22px;
}

.show {
  pointer-events: all;
  opacity: 1;
}
