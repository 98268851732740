.wrapper {
  position: fixed;
  top: var(--v-space-8);
  z-index: 410;
  width: max-content;
  transform: translateX(-100%);
  inset-inline-start: -100%;
  &:focus-within {
    inset-inline-start: 50%;
  }
}
