.thinScrollbar {
  --scrollbar-bg-color: var(--v-color-background-secondary);
  --scrollbar-handle-color: var(--v-color-ornament-primary);
  overflow-y: auto;
  scrollbar-gutter: stable;
  scrollbar-color: var(--scrollbar-handle-color) var(--scrollbar-bg-color);
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-handle-color);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-bg-color);
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}

.mobileMenuContainer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--v-color-background-primary);
  transform: translateX(100%);
  inset-inline-end: 0;
  @media (--v-from-md) {
    width: var(--c-mobile-menu-width);
  }
  @media (--v-from-lg) {
    display: none;
    transform: translateX(100%);
  }
  &.isOpen {
    transform: translateX(0);
  }
}

.menuItem {
  padding-inline: var(--v-space-24);
  &:hover {
    background-color: var(--v-color-background-secondary);
    transition: 0.2s ease;
  }
  &.highlighted {
    background-color: var(--c-menu-color-active);
  }
}

.pageTopic {
  --border-width: 4px;

  display: flex;
  align-items: center;
  width: 100%;
  height: var(--v-space-48);
  padding-inline-start: calc(var(--v-space-24) - var(--border-width));
  text-align: start;
  border-inline-start: var(--border-width) solid
    var(--v-color-background-secondary);
  transition: 0.2s ease;
  &:hover {
    background-color: var(--v-color-background-secondary);
    border-inline-start-color: var(--c-menu-color-active);
  }
  &.active {
    border-inline-start-color: var(--v-color-ornament-primary);
  }
}

.pageTopicLabelText {
  display: block;
  width: 100%;
  max-width: 950px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
